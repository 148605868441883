import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { RegFormContext } from "./context/RegFormContext";
const TermsAndConditions = () => {
  const context = useContext(RegFormContext);
  if (!context) {
    throw new Error(
      "ComponentUsingFormContext must be used within a RegistrationFormProvider"
    );
  }

  const { formData, updateFormData } = context;

  const handleCheckboxChange = () => {
    updateFormData("termsAccepted", !formData["termsAccepted"]);
  };

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: "#fff",
        borderRadius: 1,
        mt: 2,
        mx: "auto",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography paragraph>
        I hereby expressly give Consent to the Indian Machine Tools
        Manufacturers’ Association (“IMTMA”) as per the requirements of the
        Digital Personal Data Protection Act, 2023.
      </Typography>
      <Typography paragraph>
        I hereby permit IMTMA and its authorized representatives to collect the
        following details of my (“Personal Data”):
      </Typography>
      <Typography component="ol" paragraph>
        <li>Name</li>
        <li>E-mail Address</li>
        <li>Contact Number</li>
      </Typography>
      <Typography paragraph>
        I agree that the Personal Data shall be used for security purposes,
        promotion of IMTMA exhibitions, training programmes, mega events, and
        all other events, exhibitions and programmes that may be organized by
        IMTMA or its affiliates.
      </Typography>
      <Typography paragraph>
        This Consent has been made by me unconditionally, out of my free will,
        not due to any undue influence by any person or party and has been read
        and fully understood by me.
      </Typography>
      <Typography paragraph>
        This Consent may be withdrawn at any point by a written communication to
        IMTMA to be addressed to{" "}
        <Link href="mailto:it.blr@imtma.in">it.blr@imtma.in</Link>.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData["termsAccepted"]}
            onChange={handleCheckboxChange}
          />
        }
        label="I accept the terms and conditions"
      />
      <Box textAlign="center" mt={2}>
        <Typography>
          <strong>© Indian Machine Tool Manufacturers' Association</strong> All
          Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
