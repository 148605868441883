import React, { createContext, useContext, useEffect, useState } from "react";
import { getDbHeader } from "../../utils/util";

// Define a fetch function for registration settings
const fetchItems = async () => {
  try {
    const response = await fetch(
      `https://apis.expoplanner.in/event/get-registration-settings`,
      {
        method: "GET",
        ...getDbHeader(),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw errorData;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Create a context for the registration settings
const RegistrationSettingsContext = createContext();

// Create a provider component
export const RegistrationSettingsProvider = ({ children }) => {
  const [formConfig, setFormConfig] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItems()
      .then((data) => {
        setFormConfig(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  // Function to update the 'required' property of items in the formConfig
  const updateRequiredFields = (itemTitle, fieldIds, requiredValue) => {
    setFormConfig((prevState) => ({
      ...prevState,
      items: prevState.items.map((item) =>
        item.title === itemTitle
          ? {
              ...item,
              fields: item.fields.map((field) =>
                fieldIds.includes(field.id)
                  ? { ...field, required: requiredValue }
                  : field
              ),
            }
          : item
      ),
    }));
  };

  return (
    <RegistrationSettingsContext.Provider
      value={{ formConfig, error, loading, updateRequiredFields }}
    >
      {children}
    </RegistrationSettingsContext.Provider>
  );
};
export const useRegistrationSettingsContext = () => {
  return useContext(RegistrationSettingsContext);
};
