import React from "react";

const Home = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>IMTMA EXPOPLANNER</h1>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  heading: {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    textAlign: "center",
  },
};

export default Home;
