import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Stack,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  Table,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import KeyIcon from "@mui/icons-material/Key";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ImageIcon from "@mui/icons-material/Image";
import CameraRollIcon from "@mui/icons-material/CameraRoll";
import DescriptionIcon from "@mui/icons-material/Description";
import ReactQuill from "react-quill";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TableRowsIcon from "@mui/icons-material/TableRows";
import MySlider from "./MySlider";
const paletteElements = [
  {
    id: "13",
    type: "grid",
    label: "Grid",
    count: 3,
    settings: { required: false, grid: 12 },
    icon: <ViewColumnIcon />,
  },
  {
    id: "1",
    type: "text",
    label: "Text Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TextFieldsIcon />,
  },
  {
    id: "2",
    type: "email",
    label: "Email Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <AlternateEmailIcon />,
  },
  {
    id: "3",
    type: "number",
    label: "Number Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <FormatListNumberedIcon />,
  },
  {
    id: "4",
    type: "password",
    label: "Password Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <KeyIcon />,
  },
  {
    id: "5",
    type: "date",
    label: "Date Picker",
    settings: { required: false, grid: 12 },
    icon: <EventIcon />,
  },
  {
    id: "6",
    type: "time",
    label: "Time Picker",
    settings: { required: false, grid: 12 },
    icon: <AccessTimeIcon />,
  },
  {
    id: "7",
    type: "checkbox",
    label: "Checkbox",
    settings: { required: false, grid: 12 },
    icon: <CheckBoxIcon />,
  },
  {
    id: "8",
    type: "radio",
    label: "Radio Button",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <RadioButtonCheckedIcon />,
  },
  {
    id: "9",
    type: "select",
    label: "Dropdown",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <ArrowDropDownCircleIcon />,
  },
  {
    id: "10",
    type: "textarea",
    label: "Text Area",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TextSnippetIcon />,
  },
  {
    id: "11",
    type: "file",
    label: "File Upload",
    settings: { required: false, grid: 12 },
    icon: <AttachFileIcon />,
  },
  {
    id: "12",
    type: "color",
    label: "Color Picker",
    settings: { required: false, grid: 12 },
    icon: <ColorLensIcon />,
  },
];
const widgetElements = [
  {
    id: "w1",
    type: "image",
    label: "Image",
    settings: {
      placeholder: "",
      required: false,
      grid: 12,
      width: 300,
      height: 200,
    },
    icon: <ImageIcon />,
  },
  {
    id: "w2",
    type: "image_slider",
    label: "Image Slider",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <CameraRollIcon />,
  },
  {
    id: "w3",
    type: "static_table",
    label: "Static Table",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TableRowsIcon />,
  },
  {
    id: "w4",
    type: "static_table",
    label: "Dynamic Table",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TableRowsIcon />,
  },
  {
    id: "w5",
    type: "date_range",
    label: "Date Range",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <DateRangeIcon />,
  },
];

const DynamicForm5 = () => {
  const [formElements, setFormElements] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEditElement, setCurrentEditElement] = useState(null);
  const [myTab, setMyTab] = useState(0);
  const [value, setValue] = useState("");

  const handleTabChange = (event, newValue) => {
    setMyTab(newValue);
  };

  const handleDragEnd = (result) => {
    console.log("end", result);
    if (!result.destination) return;
    const finalA = paletteElements.concat(widgetElements);
    if (
      result.source.droppableId !== "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const element = finalA.find((el) => el.id === result.draggableId);
      if (element) {
        const items = Array.from(formElements);
        let elm = { ...element, id: `${element.id}-${Date.now()}` };
        items.splice(result.destination.index, 0, elm);
        setFormElements(items);
      }
    }

    if (
      result.source.droppableId === "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const items = Array.from(formElements);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setFormElements(items);
    }
  };

  const handleEditClick = (element) => {
    setCurrentEditElement(element);
    setEditDialogOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: value },
    });
  };

  const handleEditSave = () => {
    setFormElements((prev) =>
      prev.map((el) =>
        el.id === currentEditElement.id ? currentEditElement : el
      )
    );
    setEditDialogOpen(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: checked },
    });
  };

  const handleOptionsChange = (e) => {
    const { value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, options: value.split(",") },
    });
  };
  const handleCountUpdate = (e) => {
    const { value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      count: value,
    });
  };

  const GridList = ({ count }) => {
    // Create an array with the specified number of items
    const items = Array.from({ length: count }, (_, index) => index + 1);

    return (
      <>
        {items.map((item, index) => (
          <Grid key={`gxxx${index}`} item xs={12 / count}>
            {index === 0 ? (
              <>
                <TextField placeholder="Name" variant="filled" fullWidth />
                <TextField placeholder="Name" variant="filled" fullWidth />
                <TextField placeholder="Name" variant="filled" fullWidth />
              </>
            ) : (
              <>
                <Box p={2} border={"1px solid #ddd"} m={2}></Box>
              </>
            )}
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Box m={2}>
      <Typography variant="h4" component="h1" gutterBottom>
        Custom dynamic page Builder
      </Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6">Page Elements</Typography>
            <Tabs value={myTab} onChange={handleTabChange}>
              <Tab label="Form Elements" />
              <Tab label="Widgets" />
            </Tabs>
            {myTab === 0 ? (
              <Box mt={1}>
                <Droppable droppableId="palette-elements" isDropDisabled={true}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {paletteElements.map((element, index) => (
                        <Draggable
                          key={element.id}
                          draggableId={element.id}
                          index={index}
                        >
                          {(provided) => (
                            <Paper
                              sx={{ p: 1 }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Stack direction={"row"} gap={1}>
                                {element.icon}
                                <Typography>{element.label}</Typography>
                              </Stack>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            ) : (
              <Box mt={1}>
                <Droppable droppableId="palette-widgets" isDropDisabled={true}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {widgetElements.map((element, index) => (
                        <Draggable
                          key={element.id}
                          draggableId={element.id}
                          index={index}
                        >
                          {(provided) => (
                            <Paper
                              sx={{ p: 1 }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Stack direction={"row"} gap={1}>
                                {element.icon}
                                <Typography>{element.label}</Typography>
                              </Stack>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            )}
          </Grid>
          <Grid item xs={9}>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
            <Box>
              <img
                alt=""
                style={{ display: "block", width: "100%" }}
                src="https://imtex2024-imtma.expoplanner.in/static/media/imtex_header1.05702e989ce83f1207ee.png"
              />
            </Box>
            <Box>
              <MySlider />
            </Box>
            <Droppable droppableId="form-elements">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 2,
                    border: "1px solid grey",
                    borderRadius: 1,
                    minHeight: "500px",
                  }}
                >
                  {formElements.length === 0 && (
                    <Typography variant="body1" color="textSecondary">
                      Drag form fields here to build your form
                    </Typography>
                  )}
                  {formElements.map((element, index) => (
                    <Draggable
                      key={element.id}
                      draggableId={element.id}
                      index={index}
                    >
                      {(provided) => (
                        <Grid item xs={element.settings.grid}>
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              padding: 2,
                              backgroundColor: "#f9f9f9",
                              borderRadius: 1,
                              border: "1px solid grey",
                              position: "relative",
                            }}
                          >
                            {element.type === "checkbox" ? (
                              <FormControlLabel
                                control={<Checkbox />}
                                label={element.label}
                              />
                            ) : element.type === "radio" ? (
                              <Box>
                                <RadioGroup
                                  aria-label="gender"
                                  name="gender"
                                  row
                                >
                                  <FormControlLabel
                                    value="female"
                                    control={
                                      <Radio
                                        inputProps={{ "aria-label": "Female" }}
                                      />
                                    }
                                    label="Female"
                                  />
                                  <FormControlLabel
                                    value="male"
                                    control={
                                      <Radio
                                        inputProps={{ "aria-label": "Male" }}
                                      />
                                    }
                                    label="Male"
                                  />
                                </RadioGroup>
                              </Box>
                            ) : element.type === "select" ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                label={element.label}
                                select
                                sx={{ marginBottom: 1 }}
                              >
                                {element.settings.options.map((option, idx) => (
                                  <MenuItem key={idx} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : element.type === "description" ? (
                              <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={setValue}
                              />
                            ) : element.type === "grid" ? (
                              <Grid container spacing={1}>
                                <GridList count={element.count} />
                              </Grid>
                            ) : element.type === "image" ? (
                              <Box>
                                <img
                                  src={`https://via.placeholder.com/300x192`}
                                  alt="image"
                                />
                              </Box>
                            ) : (
                              <TextField
                                fullWidth
                                variant="outlined"
                                label={element.label}
                                type={element.type}
                                placeholder={element.settings.placeholder}
                                sx={{ marginBottom: 1 }}
                              />
                            )}
                            <IconButton
                              size="small"
                              onClick={() => handleEditClick(element)}
                              sx={{ position: "absolute", top: 5, right: 5 }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}

                  <Droppable droppableId="my-form-elements">
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        <Grid
                          spacing={1}
                          border={"1px solid #ccc"}
                          p={1}
                          sx={{ borderRadius: "8px" }}
                        >
                          <GridList count={2} />
                          {provided.placeholder}
                        </Grid>
                      </Box>
                    )}
                  </Droppable>
                </Box>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Field</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Label"
            name="label"
            value={currentEditElement?.label || ""}
            onChange={(e) =>
              setCurrentEditElement({
                ...currentEditElement,
                label: e.target.value,
              })
            }
          />
          <TextField
            fullWidth
            margin="dense"
            label="Placeholder"
            name="placeholder"
            value={currentEditElement?.settings.placeholder || ""}
            onChange={handleEditChange}
            disabled={
              currentEditElement?.type === "checkbox" ||
              currentEditElement?.type === "radio"
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentEditElement?.settings.required || false}
                onChange={handleCheckboxChange}
                name="required"
              />
            }
            label="Required"
          />

          {currentEditElement?.type === "grid" ? (
            <TextField
              fullWidth
              type="number"
              margin="dense"
              label="Grid count"
              value={currentEditElement?.count}
              onChange={handleCountUpdate}
            />
          ) : null}
          {currentEditElement?.type === "select" ||
          currentEditElement?.type === "radio" ? (
            <TextField
              fullWidth
              margin="dense"
              label="Options (comma separated)"
              value={currentEditElement?.settings.options.join(",") || ""}
              onChange={handleOptionsChange}
            />
          ) : null}
          <TextField
            fullWidth
            margin="dense"
            label="Grid Columns (1-12)"
            name="grid"
            type="number"
            value={currentEditElement?.settings.grid || 12}
            onChange={handleEditChange}
            inputProps={{ min: 1, max: 12 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DynamicForm5;
