import React, { useContext } from "react";
import { FormWidgetProps } from "./FormWidget";
import { Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import { RegFormContext } from "./context/RegFormContext";
const MultiCheckBox = ({ form }) => {
  const context = useContext(RegFormContext);
  if (!context) {
    throw new Error(
      "ComponentUsingFormContext must be used within a RegistrationFormProvider"
    );
  }

  const { formData, updateFormData } = context;
  return (
    <Grid container spacing={2}>
      {form.list?.map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={formData[form.id]?.includes(item)}
                onChange={() => {
                  const myItems = formData[form.id]?.includes(item)
                    ? formData[form.id].filter(
                        (checkedItem) => checkedItem !== item
                      )
                    : [...formData[form.id], item];
                  updateFormData(form.id, myItems);
                }}
              />
            }
            label={item}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MultiCheckBox;
