import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";
import { Box } from "@mui/material";

export default function MySlider() {
  return (
    <Box
      className="image-slider-container"
      sx={{
        maxWidth: "800px",
        ml: "auto",
        mr: "auto",
        mt: 2,
        mb: 2,
        border: "1px solid #ccc",
        p: 4,
      }}
    >
      <Slider dots={true}>
        <div>
          <img src="https://via.placeholder.com/800x200" />
        </div>
        <div>
          <img src="https://via.placeholder.com/800x200" />
        </div>
        <div>
          <img src="https://via.placeholder.com/800x200" />
        </div>
        <div>
          <img src="https://via.placeholder.com/800x200" />
        </div>
      </Slider>
    </Box>
  );
}
