import { useEffect, useState } from "react";

const useCities = (countryCode, stateCode) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://apis.expoplanner.in/visitor/get-cities/${countryCode}/${stateCode}`
        );
        const data = await response.json();
        setCities(data);
      } catch (error) {
        console.error("Failed to fetch cities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, [countryCode, stateCode]);

  return { cities, loading };
};

export default useCities;
