import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import useCountries from "./hooks/useCountries";
import FormLabel from "../FormLabel";
import { RegFormContext } from "../context/RegFormContext";
import { useRegistrationSettingsContext } from "../context/RegistrationSettingsContext";
const CountrySelect = ({ form }) => {
  const { formData, updateFormData } = useContext(RegFormContext);
  const { updateRequiredFields } = useRegistrationSettingsContext();
  const { countries, loading } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (!loading && countries.length > 0) {
      const india = countries.find((country) => country.code === "IN");
      setSelectedCountry(india || null);
    }
  }, [loading, countries]);

  useEffect(() => {
    updateFormData(form.id, selectedCountry);
    if (selectedCountry) {
      if (selectedCountry.name !== "India") {
        updateRequiredFields(
          "Company Information",
          ["city", "stateRegion"],
          false
        );
      } else {
        updateRequiredFields(
          "Company Information",
          ["city", "stateRegion"],
          true
        );
      }
    }
  }, [selectedCountry]);

  return (
    <Autocomplete
      size="small"
      options={countries}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.code}>
          <ListItemAvatar>
            <Avatar
              src={option.flag}
              alt={option.name}
              style={{ width: 24, height: 24 }}
            />
          </ListItemAvatar>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<FormLabel label={form.label} required={form.required} />}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={selectedCountry}
      onChange={(event, newValue) => setSelectedCountry(newValue)}
    />
  );
};

export default CountrySelect;
