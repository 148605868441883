import React, { useContext, useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress, Box } from "@mui/material";
import useStates from "./hooks/useStates";
import FormLabel from "../FormLabel";
import { RegFormContext } from "../context/RegFormContext";
const StateSelect = ({ form }) => {
  const { formData, updateFormData } = useContext(RegFormContext);
  const { states, loading } = useStates(formData["country"]?.code || null);
  const [selectedState, setSelectedState] = useState(formData[form.id] || null);

  useEffect(() => {
    updateFormData(form.id, selectedState);
  }, [selectedState]);
  return (
    <Box>
      <Autocomplete
        size="small"
        options={states || []}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<FormLabel label={form.label} required={form.required} />}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        value={formData[form.id]}
        onChange={(event, newValue) => setSelectedState(newValue)}
      />
    </Box>
  );
};

export default StateSelect;
