import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Editor from "../quill/Editor";
import Quill from "quill";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toastError } from "../utils/util";
import { API_ENDPOINT } from "../constants";
import { toast } from "react-toastify";

const Delta = Quill.import("delta");

const MyEmailTemplate = () => {
  const quillRef = useRef();
  const [open, setOpen] = useState(false);
  const [loadingTest, setloadingTest] = useState(false);
  const [savingTest, setsavingTest] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async () => {
    const htmlCode = quillRef.current.root.innerHTML;
    try {
      setloadingTest(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}event/send-test-email-testapp?type=imtex2025`,
        {
          email: htmlCode,
          recipientName: formData.name,
          recipientEmail: formData.email,
        }
      );
      toast.success(data.message);
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setloadingTest(false);
    }
  };

  const onSubmit = async () => {
    const htmlCode = quillRef.current.root.innerHTML;
    try {
      setsavingTest(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}event/email-save-testapp?type=imtex2025`,
        {
          email: htmlCode,
        }
      );
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setsavingTest(false);
    }
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      if (quillRef.current) {
        try {
          const { data } = await axios.get(
            `${API_ENDPOINT}event/get-email-testapp?type=imtex2025`
          );
          if (data) {
            const template = data.email;
            quillRef.current.root.innerHTML = template;
          } else {
            toast.error("No existing template found!");
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    };
    fetchTemplate();
  }, []);

  return (
    <Box m={2}>
      <Container>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <h2>Email Template Builder</h2>
          <Typography variant="body1">
            <b>*Plz note: </b> Use <code>{`{name}`}</code> to display name and
            use <code>{`{qr_code}`}</code> for the qr code display. These
            variables will be used later to render the relevant name and qr
            code. To add activation link add <code>{`{activation_link}`}</code>{" "}
            in your styled link.
          </Typography>
          <br />
          <Editor ref={quillRef} />
          <Box gap={2} mt={2} display={"flex"} justifyContent={"end"}>
            <LoadingButton
              loading={loadingTest}
              onClick={handleOpen}
              variant="contained"
            >
              Test/Preview Email
            </LoadingButton>
            <LoadingButton
              loading={savingTest}
              onClick={onSubmit}
              variant="contained"
            >
              Submit/Save
            </LoadingButton>
          </Box>
        </Paper>
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          p={4}
          bgcolor="background.paper"
          style={{ margin: "auto", marginTop: "10%", maxWidth: 400 }}
        >
          <Typography variant="h6" gutterBottom>
            Test/Preview Email
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <LoadingButton
              loading={loadingTest}
              variant="contained"
              color="primary"
              onClick={handleFormSubmit}
            >
              Submit
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={handleClose}>
              Cancel
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MyEmailTemplate;
