import { useEffect, useState } from "react";

const useStates = (countryCode) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!countryCode) return;

    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://apis.expoplanner.in/visitor/get-states-of-country/${countryCode}`
        );
        const data = await response.json();
        const stateData = data.map((state) => ({
          name: state.name,
          code: state.isoCode,
        }));
        setStates(stateData);
      } catch (error) {
        console.error("Failed to fetch states:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStates();
  }, [countryCode]);

  return { states, loading };
};

export default useStates;
