import React from "react";
import { Box, Typography, Chip, Stack } from "@mui/material";
import { IconMap } from "./SocialMediaForm";

const LabelValue = ({ label, value }) => {
  const renderValue = () => {
    if (typeof value === "string") {
      return <Typography color="textPrimary">{value}</Typography>;
    } else if (Array.isArray(value)) {
      return (
        <Box display="flex" flexWrap="wrap" gap={1}>
          {value.map((item, index) => (
            <Chip key={index} label={item} />
          ))}
        </Box>
      );
    } else if (typeof value === "object" && value !== null) {
      if ("name" in value) {
        // If the object has a "name" property, display only its value
        return <Typography color="textPrimary">{value.name}</Typography>;
      } else {
        // Otherwise, display all key-value pairs
        return (
          <Box mt={2}>
            {Object.entries(value).map(([key, val]) => (
              <Stack direction={"row"} mt={1} key={key}>
                <div>{IconMap[key]}</div>
                <Typography sx={{ ml: 2 }} color="textPrimary" variant="body2">
                  {val}
                </Typography>
              </Stack>
            ))}
          </Box>
        );
      }
    }
    return null;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
      <Typography
        sx={{
          margin: "0px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 550,
          fontSize: "0.875rem",
          lineHeight: 1.57,
          letterSpacing: "0.00714em",
          color: "rgb(57, 112, 130)",
        }}
        variant="subtitle2"
        color="textSecondary"
      >
        {label}
      </Typography>
      {renderValue()}
    </Box>
  );
};

export default LabelValue;
