import { toast } from "react-toastify";

export function toastError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message);
  } else {
    toast.error("An error occurred. Please try again.");
  }
}
export const getDbHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      dbauth:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYiI6ImltdGV4MjAyNSIsImlhdCI6MTcyMzcwMDkyMSwiZXhwIjoxNzU1MjU4NTIxfQ.07gBIH-rkRp9Q-6GHRORREC3w15Vj-JxbHMH7oorekQ",
    },
  };
};
