export const createDefaultRegOb = function (formConfig) {
  var regOb = {};
  var defaultValueMap = {
    string: "",
    array: [],
    object: {},
  };

  formConfig.items.forEach(function (section) {
    section.fields.forEach(function (field) {
      regOb[field.id] =
        defaultValueMap[field.valueType] !== undefined
          ? defaultValueMap[field.valueType]
          : null;
    });
  });

  regOb.preview = false;
  regOb.termsAccepted = false;

  return regOb;
};
