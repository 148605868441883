export const SELECT = "SELECT";
export const TEXT = "TEXT";
export const PHONE = "PHONE";
export const TEL = "TEL";
export const SOCIAL = "SOCIAL";
export const EMAIL = "EMAIL";
export const COUNTRY = "COUNTRY";
export const STATE = "STATE";
export const CITY = "CITY";
export const CHECKBOX = "CHECKBOX";
export const MULTICHECKBOX = "MULTICHECKBOX";
export const PRODUCT_CATEGORY = "PRODUCT_CATEGORY";
export const BRANDS_LOGO =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/footer_final_l.png";
export const FOOTER_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Footer+BG.png";
export const EXOPLANNER_LOGO =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/Expoplanner.png";
export const BADGE_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX2025BadgeBG.png";
export const HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Banner_Visitor+registration.png";
export const LOCAL = "local";
export const PRODUCTION = "production";
let state = PRODUCTION;
export const API_ENDPOINT =
  state !== LOCAL ? "https://apis.expoplanner.in/" : "http://localhost:5002/";
