import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

export default function AnchorTemporaryDrawer() {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState((prev) => !prev);
  };
  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer}>bottom</Button>
        <Drawer anchor="bottom" open={state} onClose={toggleDrawer}>
          <Box>
            <p>
              The InfiniteLoader component was created to help break large data
              sets down into chunks that could be just-in-time loaded as they
              were scrolled into view. It can also be used to create infinite
              loading lists (e.g. Facebook or Twitter). Here's a basic example
              of how you might implement that:
            </p>
            <p>
              The InfiniteLoader component was created to help break large data
              sets down into chunks that could be just-in-time loaded as they
              were scrolled into view. It can also be used to create infinite
              loading lists (e.g. Facebook or Twitter). Here's a basic example
              of how you might implement that:
            </p>
            <p>
              The InfiniteLoader component was created to help break large data
              sets down into chunks that could be just-in-time loaded as they
              were scrolled into view. It can also be used to create infinite
              loading lists (e.g. Facebook or Twitter). Here's a basic example
              of how you might implement that:
            </p>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
