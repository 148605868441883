import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BannerSlider.css";
import { Box } from "@mui/material";
export const ADS = [
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+1.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+2.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+3.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+4.png",
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_AD+Banner+5.png",
];
export default function BannerSlider() {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Box className="reg-img-con">
      <Slider {...sliderSettings}>
        {ADS.map((ad) => (
          <div>
            <img src={ad} />
          </div>
        ))}
      </Slider>
    </Box>
  );
}
