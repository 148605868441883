import { useEffect, useState } from "react";
import { getDbHeader } from "../../utils/util";
const fetchItems = async () => {
  try {
    const response = await fetch(
      `https://apis.expoplanner.in/event/get-product-categories`,
      {
        method: "GET",
        ...getDbHeader(),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw errorData;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const useProductCategories = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItems()
      .then((fetchedData) => {
        setData(fetchedData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return { data, error, loading };
};

export default useProductCategories;
