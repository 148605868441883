import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import FormWidget from "./FormWidget";
const FormComponent = ({ formConfig }) => {
  return (
    <Box>
      {formConfig.items.map((section, index) => (
        <Box key={index} mt={index !== 0 ? 2 : 0}>
          <Section title={section.title} fields={section.fields} />
        </Box>
      ))}
    </Box>
  );
};

const Section = ({ title, fields }) => {
  return (
    <Paper elevation={0} sx={{ borderRadius: 1, overflow: "hidden" }}>
      <Box p={1} pl={2} bgcolor="#397082" color="white">
        <Typography fontWeight={"bold"}>{title}</Typography>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.wd ? field.wd : 4} key={index}>
              <FormWidget form={field} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default FormComponent;
