import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BadgeToPrint } from "../badge/BadgeToPrint";
import { getDbHeader, toastError } from "../utils/util";
import MuPb from "../widgets/MuPb";
import { API_ENDPOINT, HEADER } from "./myconst";
import { useParams } from "react-router-dom";
const RegisrationQr = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const { linkCode } = useParams();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-user?uid=${linkCode}`,
        getDbHeader()
      );
      setUser(data);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }
  if (loading) {
    return <MuPb />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <img
        src={HEADER}
        style={{ display: "block", width: "100%", margin: "16px auto" }}
      />
      <p style={{ fontWeight: "bold", fontSize: "19px" }}>
        Thank you for registering!
      </p>
      <p>
        An email is sent to you confirming your registration for
        <strong> IMTEX 2025</strong>
      </p>
      <div id="badge-to-print">
        {user && <BadgeToPrint user={user} isPreview={true} />}
      </div>
      <Typography fontWeight={"bold"} mt={2} mb={2}>
        Please show your E-Badge at the Registration Counter to collect your
        Printed Badge.
      </Typography>
      <Box px={2} py={3} borderRadius="borderRadius">
        <Typography variant="body1" gutterBottom>
          Once you activate the link you can login and start using the IMTEX
          2025 online portal.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Visitor Badge: You may use fast-track registration at the event, to do
          so please take the print copy of your badge from your profile, by
          using the Print Badge button.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Advantages of Pre-registering on Expoplanner:
        </Typography>

        <ul>
          <Typography component="li" variant="body2">
            Avoiding queues
          </Typography>
          <Typography component="li" variant="body2">
            Safe & Secure entry to the venue
          </Typography>
          <Typography component="li" variant="body2">
            Know the products displayed
          </Typography>
          <Typography component="li" variant="body2">
            Search the booths from the floor plan
          </Typography>
          <Typography component="li" variant="body2">
            Connect and schedule your meetings
          </Typography>
        </ul>

        <Typography variant="h5" align="center" gutterBottom>
          IMTEX Team
        </Typography>
        <Typography sx={{ mt: 2 }} variant="body1" color="textSecondary">
          <b>Note:</b>If you haven't received our email, please check your spam
          or junk folder. Sometimes, emails might be mistakenly classified as
          spam.
        </Typography>
      </Box>
    </div>
  );
};

export default RegisrationQr;
