import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import {
  CHECKBOX,
  CITY,
  COUNTRY,
  EMAIL,
  FieldType,
  MULTICHECKBOX,
  PHONE,
  PRODUCT_CATEGORY,
  SELECT,
  SOCIAL,
  STATE,
  TEL,
  TEXT,
  WidgetType,
} from "./myconst";
import { MuiTelInput } from "mui-tel-input";
import CountrySelect from "./countrystatecity/countryselect";
import StateSelect from "./countrystatecity/stateselect";
import CitySelect from "./countrystatecity/cityselect";
import ProductCategory from "./ProductCategory";
import SocialMediaForm from "./SocialMediaForm";
import FormLabel from "./FormLabel";
import MultiCheckBox from "./MultiCheckBox";
import LabelValue from "./LabelValue";
import { RegFormContext } from "./context/RegFormContext";

const FormWidget = ({ form }) => {
  const context = useContext(RegFormContext);
  if (!context) {
    throw new Error(
      "ComponentUsingFormContext must be used within a RegistrationFormProvider"
    );
  }

  const { formData, updateFormData } = context;
  return (
    <Box id={form.id}>
      {form.type === TEXT || form.type === EMAIL || form.type === TEL ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <TextField
            size="small"
            value={formData[form.id]}
            onChange={(e) => {
              updateFormData(form.id, e.target.value);
            }}
            label={<FormLabel label={form.label} required={form.required} />}
            type={form.type}
            fullWidth
          />
        )
      ) : form.type === MULTICHECKBOX ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <MultiCheckBox form={form} />
        )
      ) : form.type === CHECKBOX ? (
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={form.label}
        />
      ) : form.type === SELECT ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <Autocomplete
            size="small"
            options={form.list || []}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <FormLabel label={form.label} required={form.required} />
                }
                variant="outlined"
              />
            )}
            value={formData[form.id]}
            onChange={(event, newValue) =>
              updateFormData(form.id, newValue === null ? "" : newValue)
            }
          />
        )
      ) : form.type === PHONE ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <MuiTelInput
            fullWidth
            size="small"
            label={<FormLabel label={form.label} required={form.required} />}
            defaultCountry="IN"
            forceCallingCode
            value={formData[form.id]}
            onChange={(newValue) => {
              updateFormData(form.id, newValue);
            }}
          />
        )
      ) : form.type === COUNTRY ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <CountrySelect form={form} />
        )
      ) : form.type === STATE ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <StateSelect form={form} />
        )
      ) : form.type === CITY ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <CitySelect form={form} />
        )
      ) : form.type === PRODUCT_CATEGORY ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <ProductCategory form={form} />
        )
      ) : form.type === SOCIAL ? (
        formData.preview ? (
          <LabelValue label={form.label} value={formData[form.id]} />
        ) : (
          <SocialMediaForm form={form} />
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default FormWidget;
