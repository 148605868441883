import { useEffect, useState } from "react";

const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://apis.expoplanner.in/visitor/get-countries"
        );
        const data = await response.json();
        const countryData = data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
          flag: country.flags.png, // URL for the flag image
        }));

        // Sort the countryData array alphabetically by country name
        const sortedCountryData = countryData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setCountries(sortedCountryData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return { countries, loading };
};

export default useCountries;
