import React from "react";

const FormLabel = ({ label, required }) => {
  return (
    <span>
      {label}
      {required && <span style={{ color: "red" }}> *</span>}
    </span>
  );
};
export default FormLabel;
